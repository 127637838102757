$navbar-height: 58px;

.gj__navbar {
  transition: background-color 200ms linear;

  & + * {
    padding-top: $navbar-height;
  }
}

.gj__navbar--scolled, .gj__navbar--opaque {
  background-color: rgba(255,255,255,0.6);
  backdrop-filter: blur(6px);
}

.gj__navbar__logotext {
  transition: filter 200ms linear;
}

.gj__navbar--not-scrolled.gj__navbar--transparent {
  .gj__navbar__logotext {
    -webkit-filter: invert(1);
    filter: invert(1);
  }
}

.gj__navbar__store-badge {
  height: 30px;
}