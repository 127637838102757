.gj__app-highlight__img {
  max-height: 500px;
}

.gj__app-highlight__icon-wrapper {
  padding-top: 100%;
  position: relative;
  max-height: 4em;
}

.gj__app-highlight__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  mask-size: contain;
  background: $logo-gradient;
  background-clip: content-box;
}

.gj__app-highlight__icon--stopwatch {
  mask-image: url('/img/icons/stopwatch.svg');
}

.gj__app-highlight__icon--location {
  mask-image: url('/img/icons/diamond.svg');
}

