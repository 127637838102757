.gj__cover {
  background: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(/img/vegetables.jpg);
  background-size: cover;
  min-height: 60vh;
}

.gj__cover__logo-text {
  -webkit-filter: invert(1);
    filter: invert(1);
}
