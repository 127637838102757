.gj__quality-chart_criteria_icon {
  max-width: 100%;
}

.gj__quality-chart_main-criteria-wrapper {
  overflow: hidden;
  padding: 0 var(--bs-gutter-x);
  margin: 0 calc(var(--bs-gutter-x) * -1) var(--bs-gutter-x);
  border-radius: 10px;
}

.gj__quality-chart_criteria-wrapper-title {
  margin: 20px 0 0;
}

.gj__quality-chart_criteria {
  margin: 20px 0;
}

.gj__quality-chart_criteria_description {
  margin-bottom: 0;
}
